import daphHead from "./assets/Daphne6.png";

import "./HeroSec.css";

function HeroSec() {
  return (
    <div className="flex flex-col md:flex-row md:justify-center xl:justify-center bg-pink-600 p-3 sm:p-3 pth-80shadow-lg">
      <div className="sm:text-center text-4xl sm:text-5xl text-slate-50 flex justify-center items-center font-sans subpixel-antialiased ">
        <div className="p-1 md:p-2">
          <h1 className="text-center">DAPHNE-A-DAY</h1>
          <h4 className="text-2xl xl:text-2xl md:mt-1 text-center subpixel-antialiased text-slate-200 sm:text-center">
            <i>A Tibetan Terrier Story</i>
          </h4>
        </div>
      </div>
      <div className="flex justify-center items-center md:mt-5 md:mb-5 xl:ml-5 ">
        <div>
          <img
            src={daphHead}
            alt=""
            className="w16 susimg justify-center items-center"
          />
        </div>
      </div>
    </div>
  );
}

export default HeroSec;
